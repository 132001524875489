#dashboard-header-main-container {
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#dashboard-right-elements {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#dashboard-header-current-user {
  margin: 0 12px;
}
