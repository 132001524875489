.paper {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
  }
  .avatar{
    margin: 1;
    color:'#C71585';
    margin-bottom: 10px;
    background-color: '#dc004e';
  }
  .form {
    width: '100%'
  }
  .submit {
    margin: 3,0,2,
  }
  .errorMessage {
    margin-top: 10px;
  }
  .container {
    margin-top: 50px;
  }